'use client'
import { Button } from '@nextui-org/react'
import React from 'react'

export default function FadingColorButton({
  children,
  className = '',
  href,
  target = '_blank',
  onClick,
}: {
  children: React.ReactNode
  className?: string
  href?: string
  target?: '_self' | '_parent' | '_top' | 'myFrame' | '_blank'
  onClick?: () => void
}) {
  const handleClick = () => {
    if (href) {
      // If there's a link, open it
      window.open(href, target)
    } else if (onClick) {
      // If there is an onClick function, call it
      onClick()
    }
  }

  // keep this for Tailwind parsing
  // bg-primary border-primary hover:border-primary
  return (
    <Button
      size="lg"
      className={
        `bg-primary border-2 border-primary
      hover:bg-white hover:border-primary hover:text-black ` + className
      }
      onPress={() => handleClick()}
    >
      {children}
    </Button>
  )
}
